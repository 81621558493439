import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 10,
  "title": "PHP",
  "subtitle": "Accept MitID, Swedish BankID, Norwegian BankID and other eID logins with PHP and Criipto Verify"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Setup OpenID-Connect-PHP`}</h2>
    <h3>{`Requirements`}</h3>
    <ol>
      <li parentName="ol">{`PHP 5.4 or greater`}</li>
      <li parentName="ol">{`CURL extension`}</li>
      <li parentName="ol">{`JSON extension`}</li>
      <li parentName="ol">{`XML extension`}</li>
    </ol>
    <h3>{`Install`}</h3>
    <ol>
      <li parentName="ol">{`Install library using composer`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`composer require jumbojett/openid-connect-php
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Include composer autoloader`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`require __DIR__ . '/vendor/autoload.php';
`}</code></pre>
    <h2>{`Implementation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`require __DIR__ . '/vendor/autoload.php';
use Jumbojett\\OpenIDConnectClient;

$oidc = new OpenIDConnectClient('https://{{YOUR_CRIIPTO_DOMAIN}}',
                                '{{YOUR_CLIENT_ID}}',
                                '{{YOUR_CLIENT_SECRET}}');

$oidc->authenticate();
// Or any other available claim based on your used authentication method: https://docs.criipto.com/verify/e-ids/
$name = $oidc->getVerifiedClaims('name');
`}</code></pre>
    <h2>{`Register callback URLs`}</h2>
    <p>{`You will need to register whichever URL you are calling `}<inlineCode parentName="p">{`$oidc->authenticate()`}</inlineCode>{` on as a Callback URL on your Criipto Application.`}</p>
    <p>{`If you're testing locally using `}<inlineCode parentName="p">{`php -S localhost:8000`}</inlineCode>{` and a file called `}<inlineCode parentName="p">{`auth.php`}</inlineCode>{` the callback url, as an example, would be `}<inlineCode parentName="p">{`https://localhost:8000/auth.php`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      